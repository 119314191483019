import { Debug } from './utils';
import { CONFIG } from './env';

function GetGamesConfig() {
    Debug("Games config path:", CONFIG.BASE_URL + CONFIG.GAMES_CONFIG);
    return fetch(CONFIG.BASE_URL + CONFIG.GAMES_CONFIG,
        {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
    ).then(function (response) {
        return response.json();
    }).then(function (gamesConfig) {
        let Games = {};

        gamesConfig.map((game) => {
            Games[game.id] = {
                id: game.id,
                name: game.name,
                description: game.description,
                thumbnail: game.thumbnail,
                src: CONFIG.BASE_URL + CONFIG.GAMES_DIR + game.src,
                tech: game.tech,
                template: game.template,
                status: game.status
            }
        });

        return Games;
    });
}

function SubmitContactForm(formData) {
    let apiURL = "http://localhost/workspace/backend/forms.php";
    apiURL = "https://astungaming.com/backend/forms.php";
    let apiData = formData;

    //Preparing payload in the form of Multipart FormData
    const payload = new FormData();
    Object.keys(apiData).forEach((key) => {
        if (Array.isArray(apiData[key])) {
            apiData[key].forEach((item, index) => {
                payload.append(`${key}[${index}]`, item);
            });
        } else {
            payload.append(key, apiData[key]);
        }
    });
    // console.log("payload:", payload);

    return fetch(apiURL,
        {
            method: 'POST',
            body: payload
        }
    ).then(function (response) {
        // console.log("Response:", response);
        return response.json();
    });
}

export { GetGamesConfig, SubmitContactForm };